import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

import parseCourseEnrollmentsQuery from '@crehana/web/Graphql/Parsers/parseCourseEnrollmentsQuery';
import COURSE_ENROLLMENTS_QUERY from '@crehana/web/Graphql/Queries/CourseEnrollmentsQuery.graphql';

import { DEFAULT_COURSE_ENROLLMENTS_QUERY_VARIABLES } from './constants';
import {
  CourseEnrollmentsQueryResponse,
  CourseEnrollmentsQueryVariables,
} from './types';

const useCourseEnrollments = (
  params?: Partial<CourseEnrollmentsQueryVariables> & {
    skip?: boolean;
  },
) => {
  const { skip, ...variables } = params || {};

  const { data, refetch, ...courseEnrollmentsQuery } = useQuery<
    CourseEnrollmentsQueryResponse,
    CourseEnrollmentsQueryVariables
  >(COURSE_ENROLLMENTS_QUERY, {
    variables: {
      ...DEFAULT_COURSE_ENROLLMENTS_QUERY_VARIABLES,
      ...variables,
    },
    skip,
    notifyOnNetworkStatusChange: true,
  });
  const totalCount = data?.me?.enrollmentSet?.totalCount || 0;

  const [totalEnrollmentsCount, setTotalEnrollmentsCount] = useState(0);

  useEffect(() => {
    if (totalCount > totalEnrollmentsCount) {
      setTotalEnrollmentsCount(totalCount);
    }
  }, [totalCount]);

  const onRefetch = (refetchVariables?: CourseEnrollmentsQueryVariables) => {
    refetch(refetchVariables);
  };

  return {
    // Return the current totalCount if totalEnrollmentsCount is not set yet
    totalEnrollmentsCount: totalEnrollmentsCount || totalCount,
    totalCount,
    courseEnrollments: parseCourseEnrollmentsQuery(data),
    onRefetch,
    refetch,
    ...courseEnrollmentsQuery,
  };
};

export default useCourseEnrollments;
