var pt = {
  dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
  dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
  monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
  amPm: ['am', 'pm'],
  DoFn: function DoFn(day) {
    return "".concat(day, "\xB0");
  }
};
export var humanized = {
  SECONDS_AGO: 'Há um momento',
  A_MINUTE_AGO: 'Há um minuto',
  MINUTES_AGO: function MINUTES_AGO(mins) {
    return "H\xE1 ".concat(mins, " minutos");
  },
  AN_HOUR_AGO: 'Há una hora',
  HOURS_AGO: function HOURS_AGO(hours) {
    return "H\xE1 ".concat(hours, " horas");
  },
  A_DAY_AGO: 'Há un dia',
  DAYS_AGO: function DAYS_AGO(days) {
    return "H\xE1 ".concat(days, " dias");
  },
  A_MONTH_AGO: 'Há un mês',
  MONTHS_AGO: function MONTHS_AGO(months) {
    return "H\xE1 ".concat(months, " meses");
  },
  A_YEAR_AGO: 'Há un ano',
  YEARS_AGO: function YEARS_AGO(years) {
    return "H\xE1 ".concat(years, " anos");
  }
};
export var remaining = {
  SECONDS_AGO: 'um momento',
  A_MINUTE_AGO: 'um minuto',
  MINUTES_AGO: function MINUTES_AGO(mins) {
    return "".concat(mins, " minutos");
  },
  AN_HOUR_AGO: 'una hora',
  HOURS_AGO: function HOURS_AGO(hours) {
    return "".concat(hours, " horas");
  },
  A_DAY_AGO: 'un dia',
  DAYS_AGO: function DAYS_AGO(days) {
    return "".concat(days, " dias");
  },
  A_MONTH_AGO: 'un mês',
  MONTHS_AGO: function MONTHS_AGO(months) {
    return "".concat(months, " meses");
  },
  A_YEAR_AGO: 'un ano',
  YEARS_AGO: function YEARS_AGO(years) {
    return "".concat(years, " anos");
  }
};
export default pt;