import { isFuture } from "./isFuture";
import { isPast } from "./isPast";
import toDate from "./toDate";

/**
 * Function to validate if an event is live, past or future given a start and end date
 * You can provide the date object itself or a date string (FULL_FORMAT)
 */
var getLiveState = function getLiveState(startDate, endDate) {
  var start = typeof startDate === 'string' ? toDate(startDate) : startDate;
  var end = typeof endDate === 'string' ? toDate(endDate) : endDate;
  if (end && isPast(end)) {
    return 'past';
  }
  if (start && isPast(start) && (!end || isFuture(end))) {
    return 'live';
  }
  return 'future';
};
export default getLiveState;