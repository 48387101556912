import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';

import GET_FEATURE_FLAG from './graphql/GetFlagOrganization.v4.b2b.graphql';
import {
  GetFlagOrganization,
  GetFlagOrganizationVariables,
} from './graphql/types/GetFlagOrganization';

type useFlagOrganizationProps = {
  flag: string;
  slug?: string;
};

const useFlagOrganization = ({ flag, slug }: useFlagOrganizationProps) => {
  const { query } = useRouter();
  const { organization } = query as { organization: string };

  const { data, loading } = useQuery<
    GetFlagOrganization,
    GetFlagOrganizationVariables
  >(GET_FEATURE_FLAG, {
    variables: { slug: organization || slug },
    context: { clientName: 'v4.b2b' },
    fetchPolicy: 'network-only',
  });

  const currentFlag = data?.b2bOrganizationCurrent?.features?.find(
    feature => feature?.slug === flag,
  );

  return {
    isActive: currentFlag?.isActive || false,
    isLoading: loading,
  };
};

export default useFlagOrganization;
