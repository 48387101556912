import { useEffect } from 'react';

import { useQuery } from '@apollo/client';

import { customLocalStorage } from '@crehana/utils';
import { useMe } from '@crehana/web';

import {
  UserTourSectionEnum,
  UserTourStatusEnum,
} from '@/shared/types/graphql/globals';

import type {
  UserTourQuery as UserTourQueryResponse,
  UserTourQueryVariables,
} from './graphql/types/UserTourQuery';
import USER_TOUR_QUERY from './graphql/UserTourQuery.graphql';

const useUserTour = ({
  section,
  skip,
}: {
  section: UserTourSectionEnum;
  skip?: boolean;
}) => {
  const { me } = useMe();

  const SECTION_LOCAL_STORAGE_KEY = `${section}-tour-${me?.originalId}`;
  const tourStatusSavedInLocalStorage = customLocalStorage.getItem(
    SECTION_LOCAL_STORAGE_KEY,
  ) as UserTourStatusEnum | undefined;
  const skipQuery = skip || !!tourStatusSavedInLocalStorage;

  const { loading, error, data } = useQuery<
    UserTourQueryResponse,
    UserTourQueryVariables
  >(USER_TOUR_QUERY, {
    variables: { section },
    skip: skipQuery,
    notifyOnNetworkStatusChange: true,
  });
  const tourStatus =
    data?.userTourSection?.completedEnum ||
    tourStatusSavedInLocalStorage ||
    UserTourStatusEnum.INCOMPLETE;

  useEffect(() => {
    if (
      !skip &&
      !loading &&
      tourStatusSavedInLocalStorage !== tourStatus &&
      (tourStatus === UserTourStatusEnum.COMPLETED ||
        tourStatus === UserTourStatusEnum.IGNORE)
    ) {
      customLocalStorage.setItem(SECTION_LOCAL_STORAGE_KEY, tourStatus);
    }
  }, [
    skip,
    loading,
    tourStatusSavedInLocalStorage,
    tourStatus,
    SECTION_LOCAL_STORAGE_KEY,
  ]);

  // TODO: Validate status in deprecated tour fields

  return {
    loading,
    error,
    tourStatus,
    isIncomplete: !loading && tourStatus === UserTourStatusEnum.INCOMPLETE,
    isIgnored: !loading && tourStatus === UserTourStatusEnum.IGNORE,
    isComplete: !loading && tourStatus === UserTourStatusEnum.COMPLETED,
  };
};

export default useUserTour;
