import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { DegreeSyllabusStatusEnum } from "../types/globals";
var parseCourseEnrollmentProject = function parseCourseEnrollmentProject(_ref) {
  var _project$degreeLearni, _project$degreeLearni2, _project$degreeLearni3;
  var project = _ref.project,
    courseId = _ref.courseId;
  return _objectSpread(_objectSpread({}, project), {}, {
    absoluteUrl: project.absoluteUrl || "/clases/v2/".concat(courseId, "/proyecto/crear/"),
    isFromDegree: !!((_project$degreeLearni = project.degreeLearningDetail) !== null && _project$degreeLearni !== void 0 && _project$degreeLearni.degreeLearning),
    isExpired: ((_project$degreeLearni2 = project.degreeLearningDetail) === null || _project$degreeLearni2 === void 0 ? void 0 : (_project$degreeLearni3 = _project$degreeLearni2.degreeLearning) === null || _project$degreeLearni3 === void 0 ? void 0 : _project$degreeLearni3.degreeSyllabus.statusEnum) === DegreeSyllabusStatusEnum.STATUS_FINISHED
  });
};
export default parseCourseEnrollmentProject;