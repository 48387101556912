import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import parseCourseEnrollmentProject from "./parseCourseEnrollmentProject";
var parseCourseEnrollment = function parseCourseEnrollment(_ref) {
  var course = _ref.course,
    isHidden = _ref.isHidden,
    project = _ref.project,
    certificate = _ref.certificate,
    participationCertificate = _ref.participationCertificate;
  var dashboardUrl = "/clases/v2/".concat(course.originalId, "/");
  var dashboardDetailUrl = "".concat(dashboardUrl, "detalle/");
  var dashboardProjectUrl = "".concat(dashboardUrl, "proyecto/");
  var playerUrl = course.lastVideoLectureUrl || "".concat(dashboardUrl, "player/");
  return _objectSpread(_objectSpread({}, course), {}, {
    progress: course.progress || 0,
    isHidden: isHidden,
    project: project ? parseCourseEnrollmentProject({
      project: project,
      courseId: course.originalId
    }) : undefined,
    dashboardDetailUrl: dashboardDetailUrl,
    dashboardProjectUrl: dashboardProjectUrl,
    playerUrl: playerUrl,
    certificateUrl: certificate === null || certificate === void 0 ? void 0 : certificate.attachmentUrl,
    participationCertificate: participationCertificate
  });
};
export default parseCourseEnrollment;