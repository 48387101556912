import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { bytesToSize, notEmpty } from "@crehana/utils";
var parseCourseAttachments = function parseCourseAttachments(_ref) {
  var courseAttachmentFragmentList = _ref.courseAttachmentFragmentList;
  return (courseAttachmentFragmentList || []).filter(notEmpty).filter(function (attachment) {
    return !!attachment.fileUrl;
  }).map(function (attachment) {
    var fileSize = Number(attachment.fileSize || '0');
    return _objectSpread(_objectSpread({}, attachment), {}, {
      name: attachment.name || attachment.filename,
      fileUrl: attachment.fileUrl,
      fileSize: fileSize,
      fileSizeLabel: bytesToSize(fileSize)
    });
  });
};
export default parseCourseAttachments;