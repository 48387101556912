import fecha from 'fecha';
import { FULL_FORMAT } from "./constants";
import locales from "./locales";
var toDate = function toDate(str) {
  var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : FULL_FORMAT;
  var lang = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'es';
  fecha.i18n = locales[lang];
  var parsedDate = fecha.parse(str, fmt);
  return parsedDate instanceof Date ? parsedDate : undefined;
};
export default toDate;