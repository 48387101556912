import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { transformData } from "@crehana/utils";
import parseCourseEnrollment from "./parseCourseEnrollment";
var parseCourseEnrollmentsQuery = function parseCourseEnrollmentsQuery(data) {
  var _data$me;
  return transformData(data === null || data === void 0 ? void 0 : (_data$me = data.me) === null || _data$me === void 0 ? void 0 : _data$me.enrollmentSet).map(function (enrollment) {
    var _enrollment$course;
    return _objectSpread({
      enrollmentId: enrollment.originalId
    }, parseCourseEnrollment({
      course: enrollment.course,
      isHidden: enrollment.isHidden,
      project: enrollment.project,
      certificate: enrollment.certificate,
      participationCertificate: (_enrollment$course = enrollment.course) === null || _enrollment$course === void 0 ? void 0 : _enrollment$course.participationCertificate
    }));
  });
};
export default parseCourseEnrollmentsQuery;