import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import parseDegreeEnrollmentsQuery from '@crehana/web/Graphql/Parsers/parseDegreeEnrollmentsQuery';
import DEGREE_ENROLLMENTS_QUERY from '@crehana/web/Graphql/Queries/DegreeEnrollmentsQuery.graphql';
import type { DegreeEnrollmentsQuery as DegreeEnrollmentsQueryResponse } from '@crehana/web/Graphql/Queries/types/DegreeEnrollmentsQuery';

import { DegreeSyllabusStatusEnum } from '@/shared/types/graphql/globals';

const useMyDegrees = (options?: { skip?: boolean }) => {
  const { data, error, ...queryResult } =
    useQuery<DegreeEnrollmentsQueryResponse>(DEGREE_ENROLLMENTS_QUERY, {
      skip: options?.skip,
      notifyOnNetworkStatusChange: true,
    });

  const { degrees, activeDegree } = useMemo(() => {
    const degreeList = parseDegreeEnrollmentsQuery(data).filter(
      d => !d.degree.isBootcamp,
    );

    const active =
      degreeList.length > 0
        ? degreeList.find(
            degree =>
              degree.statusEnum !== DegreeSyllabusStatusEnum.STATUS_FINISHED,
          ) || degreeList[0]
        : undefined;

    return { degrees: degreeList, activeDegree: active };
  }, [data]);

  const getBootcamps = () => {
    const bootcampsList = parseDegreeEnrollmentsQuery(data);

    const bootcamps = bootcampsList.filter(
      bootcamp => bootcamp.degree.isBootcamp,
    );

    return bootcamps;
  };

  return {
    ...queryResult,
    error: error,
    degrees,
    bootcamps: getBootcamps(),
    activeDegree,
  };
};

export default useMyDegrees;
