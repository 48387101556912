/**
 * Returns a string with the corresponding unit
 *
 * @param {number} bytes - The number of bytes that you need to parse
 *
 * @example
 * const bytes = 1024;
 * const parsedSize = bytesToSize(bytes);
 *
 * console.log(bytes); // 1KB
 */
var bytesToSize = function bytesToSize(bytes, decimals) {
  if (bytes === 0) {
    return '0 Bytes';
  }
  var k = 1024;
  var dm = (decimals || 2) <= 0 ? 0 : decimals || 2;
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  var i = Math.floor(Math.log(bytes) / Math.log(k));

  // TODO: enable this rule and fix the code

  return "".concat(parseFloat((bytes / Math.pow(k, i)).toFixed(dm))).concat(sizes[i]);
};
export default bytesToSize;