import { useMutation } from '@apollo/client';

import { UserTourStatusEnum } from '@/shared/types/graphql/globals';

import {
  UpdateUserTourMutation as UpdateUserTourMutationResponse,
  UpdateUserTourMutationVariables,
} from './graphql/types/UpdateUserTourMutation';
import type {
  UserTourQuery as UserTourQueryResponse,
  UserTourQueryVariables,
} from './graphql/types/UserTourQuery';
import UPDATE_USER_TOUR_MUTATION from './graphql/UpdateUserTourMutation.graphql';
import USER_TOUR_QUERY from './graphql/UserTourQuery.graphql';

const useUpdateUserTour = () => {
  const [updateUserTourMutation] = useMutation<
    UpdateUserTourMutationResponse,
    UpdateUserTourMutationVariables
  >(UPDATE_USER_TOUR_MUTATION);

  const updateUserTour = ({
    section,
    status,
  }: UpdateUserTourMutationVariables): Promise<UserTourStatusEnum> =>
    new Promise((resolve, reject) => {
      updateUserTourMutation({
        variables: { section, status },
        update: (cache, { data }) => {
          const response = data?.createOrUpdateUserTour;

          if (response?.success && response.userTour?.completedEnum) {
            let storeData: UserTourQueryResponse | null = null;

            // Update status enum if the user tour query was previously fetched
            try {
              storeData = cache.readQuery<
                UserTourQueryResponse,
                UserTourQueryVariables
              >({ query: USER_TOUR_QUERY, variables: { section } });

              if (storeData?.userTourSection) {
                cache.writeQuery<UserTourQueryResponse, UserTourQueryVariables>(
                  {
                    query: USER_TOUR_QUERY,
                    variables: { section },
                    data: {
                      ...storeData,
                      userTourSection: {
                        ...storeData.userTourSection,
                        completedEnum: response.userTour.completedEnum,
                      },
                    },
                  },
                );
              }
            } catch (e) {
              // The user tour query doesn't exist in cache yet
            }
            resolve(response.userTour.completedEnum);
          } else {
            reject(new Error(response?.response?.message || ''));
          }
        },
      }).catch(error => {
        reject(error);
      });
    });

  const skipTour = ({
    section,
  }: Pick<UpdateUserTourMutationVariables, 'section'>) =>
    updateUserTour({ section, status: UserTourStatusEnum.IGNORE });

  const completeTour = ({
    section,
  }: Pick<UpdateUserTourMutationVariables, 'section'>) =>
    updateUserTour({ section, status: UserTourStatusEnum.COMPLETED });

  return { updateUserTour, skipTour, completeTour };
};

export default useUpdateUserTour;
