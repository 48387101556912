var es = {
  dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
  dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  amPm: ['am', 'pm'],
  DoFn: function DoFn(day) {
    return "".concat(day, "\xB0");
  }
};
export var humanized = {
  SECONDS_AGO: 'Hace un momento',
  A_MINUTE_AGO: 'Hace un minuto',
  MINUTES_AGO: function MINUTES_AGO(mins) {
    return "Hace ".concat(mins, " minutos");
  },
  AN_HOUR_AGO: 'Hace una hora',
  HOURS_AGO: function HOURS_AGO(hours) {
    return "Hace ".concat(hours, " horas");
  },
  A_DAY_AGO: 'Hace un día',
  DAYS_AGO: function DAYS_AGO(days) {
    return "Hace ".concat(days, " d\xEDas");
  },
  A_MONTH_AGO: 'Hace un mes',
  MONTHS_AGO: function MONTHS_AGO(months) {
    return "Hace ".concat(months, " meses");
  },
  A_YEAR_AGO: 'Hace un año',
  YEARS_AGO: function YEARS_AGO(years) {
    return "Hace ".concat(years, " a\xF1os");
  }
};
export var remaining = {
  SECONDS_AGO: 'un momento',
  A_MINUTE_AGO: 'un minuto',
  MINUTES_AGO: function MINUTES_AGO(mins) {
    return "".concat(mins, " minutos");
  },
  AN_HOUR_AGO: 'una hora',
  HOURS_AGO: function HOURS_AGO(hours) {
    return "".concat(hours, " horas");
  },
  A_DAY_AGO: 'un día',
  DAYS_AGO: function DAYS_AGO(days) {
    return "".concat(days, " d\xEDas");
  },
  A_MONTH_AGO: 'un mes',
  MONTHS_AGO: function MONTHS_AGO(months) {
    return "".concat(months, " meses");
  },
  A_YEAR_AGO: 'un año',
  YEARS_AGO: function YEARS_AGO(years) {
    return "".concat(years, " a\xF1os");
  }
};
export default es;