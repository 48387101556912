import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { date, transformData } from "@crehana/utils";
import { DegreeLearningCourseDetailProjectTypeEnum, DegreeSyllabusStatusEnum, StatusProjectEnum } from "../../__generated__/graphql-schema";
import parseCourseAttachments from "./parseCourseAttachments";
import parseCourseEnrollment from "./parseCourseEnrollment";
export var parseDegreeEnrollmentsQuery = function parseDegreeEnrollmentsQuery(data) {
  var _data$me;
  return transformData(data === null || data === void 0 ? void 0 : (_data$me = data.me) === null || _data$me === void 0 ? void 0 : _data$me.enrollmentDegreeSet).filter(function (enrollment) {
    return !!enrollment.degreeSyllabus;
  }).map(function (enrollment) {
    var _userCapstoneProject;
    var finalProject = _objectSpread(_objectSpread({}, enrollment.degreeSyllabus.userCapstoneProject), {}, {
      isEnabled: enrollment.degreeSyllabus.statusEnum === DegreeSyllabusStatusEnum.STATUS_CLOSURE,
      description: enrollment.degreeSyllabus.capstoneProjectTitle,
      startDate: enrollment.degreeSyllabus.capstoneProjectStartDate,
      deadline: enrollment.degreeSyllabus.capstoneProjectDeadline,
      type: 'FINAL',
      hasProject: !!enrollment.degreeSyllabus.userCapstoneProject,
      absoluteUrl: ((_userCapstoneProject = enrollment.degreeSyllabus.userCapstoneProject) === null || _userCapstoneProject === void 0 ? void 0 : _userCapstoneProject.absoluteUrl) || ''
    });
    var courseCount = 0;
    var completedCourseCount = 0;
    var requiredProjectsCount = 0;
    var optionalProjectsCount = 0;
    var completedRequiredProjectCount = 0;
    var approvedRequiredProjectCount = 0;
    var approvedOptionalProjectCount = 0;
    var approvedProjectCount = 0;
    var months = transformData(enrollment.degreeSyllabus.degreeLearningSet).map(function (month, monthIndex) {
      var monthRequiredProjects = {
        approved: 0,
        total: 0
      };
      var courses = transformData(month.coursesDetail).filter(function (courseDetail) {
        return !!courseDetail.course;
      }).map(function (courseDetail) {
        var _project, _project2, _project3, _courseModuleSet, _parsedCourse$project, _parsedCourse$project2;
        courseCount += 1;
        if ((courseDetail.course.progress || 0) >= 100) {
          completedCourseCount += 1;
        }
        var projectIsApproved = ((_project = courseDetail.course.project) === null || _project === void 0 ? void 0 : _project.currentActionState) === StatusProjectEnum.CERTIFIED;
        var projectIsCompleted = projectIsApproved || ((_project2 = courseDetail.course.project) === null || _project2 === void 0 ? void 0 : _project2.currentActionState) === StatusProjectEnum.PUBLISHED || ((_project3 = courseDetail.course.project) === null || _project3 === void 0 ? void 0 : _project3.currentActionState) === StatusProjectEnum.OBSERVED;
        var projectIsRequired = courseDetail.projectTypeEnum === DegreeLearningCourseDetailProjectTypeEnum.PROJECT_TYPE_REQUIRED;
        if (projectIsRequired) {
          if (projectIsApproved) {
            approvedRequiredProjectCount += 1;
            approvedProjectCount += 1;
          }
          if (projectIsCompleted) {
            completedRequiredProjectCount += 1;
          }
          requiredProjectsCount += 1;
          monthRequiredProjects = {
            approved: projectIsApproved ? monthRequiredProjects.approved + 1 : monthRequiredProjects.approved,
            total: monthRequiredProjects.total + 1
          };
        } else {
          if (projectIsApproved) {
            approvedOptionalProjectCount += 1;
            approvedProjectCount += 1;
          }
          optionalProjectsCount += 1;
        }
        var attachmentList = parseCourseAttachments({
          courseAttachmentFragmentList: transformData(courseDetail.course.attachmentSet)
        });
        if (courseDetail.course.attachedFilesUrl) {
          attachmentList.push({
            fileUrl: courseDetail.course.attachedFilesUrl
          });
        }
        var parsedCourse = parseCourseEnrollment({
          course: courseDetail.course,
          project: courseDetail.course.project
        });
        var degreeProjectType = 'EMPTY';
        if (courseDetail.projectTypeEnum) {
          degreeProjectType = projectIsRequired ? 'REQUIRED' : 'OPTIONAL';
        }
        return _objectSpread(_objectSpread(_objectSpread({}, courseDetail), parsedCourse), {}, {
          order: courseCount,
          modules: ((_courseModuleSet = courseDetail.course.courseModuleSet) === null || _courseModuleSet === void 0 ? void 0 : _courseModuleSet.edges.length) || 0,
          isEnabled: !courseDetail.courseProtection,
          startDate: courseDetail.courseStartDate ? date.toDate(courseDetail.courseStartDate) : undefined,
          attachmentList: courseDetail.course.isEnrollment ? attachmentList : [],
          project: _objectSpread({
            order: projectIsRequired ? requiredProjectsCount : optionalProjectsCount,
            isEnabled: !courseDetail.courseProtection,
            description: courseDetail.projectTitle,
            startDate: courseDetail.projectStartDate,
            deadline: courseDetail.projectDeadline,
            type: degreeProjectType,
            hasProject: !!parsedCourse.project,
            absoluteUrl: ((_parsedCourse$project = parsedCourse.project) === null || _parsedCourse$project === void 0 ? void 0 : _parsedCourse$project.absoluteUrl) || "/clases/v2/".concat(courseDetail.course.originalId, "/proyecto/"),
            url: ((_parsedCourse$project2 = parsedCourse.project) === null || _parsedCourse$project2 === void 0 ? void 0 : _parsedCourse$project2.absoluteUrl) || "/clases/v2/".concat(courseDetail.course.originalId, "/proyecto/")
          }, courseDetail.course.project)
        });
      });
      var liveEvents = transformData(month.eventSchedule).map(function (event) {
        var _event$eventLive, _event$eventLive2;
        return _objectSpread(_objectSpread({}, event), {}, {
          title: event.title || ((_event$eventLive = event.eventLive) === null || _event$eventLive === void 0 ? void 0 : _event$eventLive.title),
          eventLink: ((_event$eventLive2 = event.eventLive) === null || _event$eventLive2 === void 0 ? void 0 : _event$eventLive2.absoluteUrl) || event.eventExternalLink
        });
      });
      var items = [].concat(_toConsumableArray(courses.map(function (course) {
        return {
          startDate: course.startDate,
          course: _objectSpread(_objectSpread({}, course), {}, {
            project: _objectSpread(_objectSpread({}, course.project), {}, {
              absoluteUrl: course.project.absoluteUrl || "/clases/v2/".concat(course.originalId, "/proyecto/crear/")
            })
          })
        };
      })), _toConsumableArray(liveEvents.map(function (event) {
        return {
          startDate: event.startDate ? date.toDate(event.startDate) : undefined,
          event: event
        };
      })));
      var isFinalMonth = monthIndex === enrollment.degreeSyllabus.degreeLearningSet.edges.length - 1;

      // Include capstone project in the last month
      if (isFinalMonth) {
        items.push({
          startDate: finalProject.startDate ? date.toDate(finalProject.startDate) : undefined,
          finalProject: finalProject
        });
      }
      items.sort(function (a, b) {
        if (!a.startDate) {
          return 1;
        }
        if (!b.startDate) {
          return -1;
        }
        return a.startDate.getTime() - b.startDate.getTime();
      });
      return _objectSpread(_objectSpread({}, month), {}, {
        items: items,
        progress: month.progressPercentage || 0,
        pendingProjects: monthRequiredProjects.total - monthRequiredProjects.approved,
        state: date.getLiveState(month.startDate, month.endDate),
        isFinalMonth: isFinalMonth
      });
    });
    var events = transformData(enrollment.degreeSyllabus.eventsSchedule).map(function (event) {
      var _event$eventLive3, _event$eventLive4;
      return _objectSpread(_objectSpread({}, event), {}, {
        title: event.title || ((_event$eventLive3 = event.eventLive) === null || _event$eventLive3 === void 0 ? void 0 : _event$eventLive3.title),
        eventLink: ((_event$eventLive4 = event.eventLive) === null || _event$eventLive4 === void 0 ? void 0 : _event$eventLive4.absoluteUrl) || event.eventExternalLink
      });
    });
    events.sort(function (a, b) {
      if (!a.startDate) {
        return 1;
      }
      if (!b.startDate) {
        return -1;
      }
      return date.toDate(a.startDate).getTime() - date.toDate(b.startDate).getTime();
    });
    var degreeCohortAttachments = parseCourseAttachments({
      courseAttachmentFragmentList: enrollment.degreeSyllabus.attachments
    });
    return _objectSpread(_objectSpread(_objectSpread({}, enrollment.degreeSyllabus.degree), enrollment.degreeSyllabus), {}, {
      months: months,
      attachments: enrollment.degreeSyllabus.degree.syllabusUrl ? [{
        name: "S\xEDlabo - ".concat(enrollment.degreeSyllabus.degree.title),
        fileUrl: enrollment.degreeSyllabus.degree.syllabusUrl
      }].concat(_toConsumableArray(degreeCohortAttachments)) : degreeCohortAttachments,
      futureEvents: events.filter(function (event) {
        var liveState = date.getLiveState(event.startDate, event.endDate);
        return liveState === 'live' || liveState === 'future';
      }),
      courses: {
        completed: completedCourseCount,
        total: courseCount
      },
      requiredProjects: {
        completed: completedRequiredProjectCount,
        approved: approvedRequiredProjectCount,
        total: requiredProjectsCount
      },
      optionalProjects: {
        approved: approvedOptionalProjectCount,
        total: optionalProjectsCount
      },
      projects: {
        approved: finalProject.currentActionState === StatusProjectEnum.CERTIFIED ? approvedProjectCount + 1 : approvedProjectCount,
        total: courseCount + 1
      },
      finalProject: finalProject,
      certificateUrl: enrollment.certificateAbsoluteUrl,
      participationCertificateUrl: enrollment.participationCertificateAbsoluteUrl
    });
  });
};
export default parseDegreeEnrollmentsQuery;