import { CourseEnrollmentsQueryVariables } from './types';

export const DEFAULT_COURSE_ENROLLMENTS_QUERY_VARIABLES: CourseEnrollmentsQueryVariables =
  {
    first: 12,
    lastActions: true,
    isHidden: false,
    isActive: false,
    progressState: undefined,
    assignedCourses: null,
  };
