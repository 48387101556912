var en = {
  dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  amPm: ['am', 'pm'],
  DoFn: function DoFn(day) {
    return "".concat(day, "\xB0");
  }
};
export var humanized = {
  SECONDS_AGO: 'A moment ago',
  A_MINUTE_AGO: 'A minute ago',
  MINUTES_AGO: function MINUTES_AGO(mins) {
    return "".concat(mins, " minutes ago");
  },
  AN_HOUR_AGO: 'An hour ago',
  HOURS_AGO: function HOURS_AGO(hours) {
    return "".concat(hours, " hours ago");
  },
  A_DAY_AGO: 'A day ago',
  DAYS_AGO: function DAYS_AGO(days) {
    return "".concat(days, " days ago");
  },
  A_MONTH_AGO: 'A month ago',
  MONTHS_AGO: function MONTHS_AGO(months) {
    return "".concat(months, " months ago");
  },
  A_YEAR_AGO: 'A year ago',
  YEARS_AGO: function YEARS_AGO(years) {
    return "".concat(years, " years ago");
  }
};
export var remaining = {
  SECONDS_AGO: 'a moment',
  A_MINUTE_AGO: 'a minute',
  MINUTES_AGO: function MINUTES_AGO(mins) {
    return "".concat(mins, " minutes");
  },
  AN_HOUR_AGO: 'an hour',
  HOURS_AGO: function HOURS_AGO(hours) {
    return "".concat(hours, " hours");
  },
  A_DAY_AGO: 'a day',
  DAYS_AGO: function DAYS_AGO(days) {
    return "".concat(days, " days");
  },
  A_MONTH_AGO: 'a month',
  MONTHS_AGO: function MONTHS_AGO(months) {
    return "".concat(months, " months");
  },
  A_YEAR_AGO: 'a year',
  YEARS_AGO: function YEARS_AGO(years) {
    return "".concat(years, " years");
  }
};
export default en;